const footerbar = () =>{
    return (
        <div className='footer text-white w-100'>
            <div className='col-12 py-4'>
                <p className='d-inline'>© 2023 Copyright : </p>  
                <a className="text-white" href="https://example.com/">JARSOFTWARE.COM</a>
            </div>
        </div>
    )
}

export default footerbar